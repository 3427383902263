// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---pages-404-tsx": () => import("./../../../pages/404.tsx" /* webpackChunkName: "component---pages-404-tsx" */),
  "component---pages-about-tsx": () => import("./../../../pages/about.tsx" /* webpackChunkName: "component---pages-about-tsx" */),
  "component---pages-blog-article-tsx": () => import("./../../../pages/blog-article.tsx" /* webpackChunkName: "component---pages-blog-article-tsx" */),
  "component---pages-blog-tsx": () => import("./../../../pages/blog.tsx" /* webpackChunkName: "component---pages-blog-tsx" */),
  "component---pages-cancellation-policy-tsx": () => import("./../../../pages/cancellation-policy.tsx" /* webpackChunkName: "component---pages-cancellation-policy-tsx" */),
  "component---pages-contact-tsx": () => import("./../../../pages/contact.tsx" /* webpackChunkName: "component---pages-contact-tsx" */),
  "component---pages-index-tsx": () => import("./../../../pages/index.tsx" /* webpackChunkName: "component---pages-index-tsx" */),
  "component---pages-memberships-tsx": () => import("./../../../pages/memberships.tsx" /* webpackChunkName: "component---pages-memberships-tsx" */),
  "component---pages-not-found-tsx": () => import("./../../../pages/not-found.tsx" /* webpackChunkName: "component---pages-not-found-tsx" */),
  "component---pages-terms-and-conditions-tsx": () => import("./../../../pages/terms-and-conditions.tsx" /* webpackChunkName: "component---pages-terms-and-conditions-tsx" */)
}

